.about {
  min-height: 50vh;
  background-color: #2b2b2b;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.about__avatar {
  width: 40%;
  min-width: 150px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.about__avatar > img {
  border-radius: 50%;
  padding: 20px;
  object-fit: contain;
  margin-top: 80px;
  margin-right: 20px;
}
.about__section {
  width: 60%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 70px 40px 10px;
}
.about__section > h2 {
  padding: 10px;
  color: white;
}
.about__section > p {
  color: grey;
  padding: 10px;
  max-width: 650px;
}
@media (max-width: 500px) {
  .about__avatar {
    display: none;
  }
  .about__section {
    padding: 0;
    margin: 0;
    width: 100%;
    padding-top: 25px;
    padding-left: 20px;
    padding-bottom: 30px;
    padding-right: 10px;
  }

  .about__section > p {
    padding-right: 0;
    margin-right: 2px;
  }
}
