.header {
  width: 100%;
  background: url(../assets/sunset1.jpg);
  background-size: cover;
  background-position: bottom;

  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}
/*---------Navegacion-----------*/
.header__nav {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: bold;
  font-size: 12px;
  height: 50px;
}
.header__navLink {
  padding: 10px;
  margin-right: 10px;
  text-shadow: 1px 1px 1px 1px black;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.header__navLink:hover {
  color: orange;
}
/*---------Info-------------*/
.header__info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header__info > h1 {
  width: 80%;
  text-align: center;
  font-size: 90px;
  padding: 10px;
  font-weight: 600;
  text-shadow: 1px 1px 1px black;
}
.header__info > p {
  width: 80%;
  text-align: center;
  color: white;
  font-size: 20px;
  text-shadow: 1px 1px 1px black;
}
.header__infoIcons {
  width: 80%;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header__infoIcons a {
  padding: 20px;
  transition: 300ms all;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.068);
  box-shadow: 0 0 20px -10px white;
}
.header__infoIcons > a:hover {
  transform: scale(1.2);
}
/*----------Botón-----------*/
.header__more {
  margin-bottom: 40px;
  cursor: pointer;
  transition: 300ms all;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__more:hover {
  transform: scale(1.2);
}

/*------MEDIA QUERIES---------*/
@media (max-width: 900px) {
  .header__info > h1 {
    font-size: 60px;
  }
  .header__info > p {
    font-size: 15px;
    padding: 20px;
  }
}
@media (max-width: 550px) {
  .header_nav {
    width: 100%;
  }

  .header__info > h1 {
    font-size: 50px;
    padding: 20px;
  }
  .header__info > p {
    font-size: 15px;
    padding: 20px;
  }
  .header__more {
    padding-bottom: 40px;
  }
}
@media (max-width: 500px) {
  #inicio {
    display: none;
  }
  #resumeOption {
    display: none;
  }
  .header__info > h1 {
    font-size: 35px;
  }
  .header__info > p {
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
  }
  .header__navLink {
    letter-spacing: 1.5px;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 11px;
  }
}

@media (max-width: 350px) {
  .header__navLink {
    margin: 10px 0;
    padding: 5px;
  }
}
