.resume {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: white;
}
.resume__container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
.resume__containerTitle {
  padding: 10px;
  width: 40%;
  min-width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.resume__containerTitle > h2 {
  margin-right: 50px;
  text-decoration: underline orange;
}
.resume__containerInfo {
  padding: 10px;
  width: 60%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.resume__containerTechs {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 10px;
}
.resume__containerTechs > img {
  margin: 10px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  border-radius: 99px;
}
.circle {
  border-radius: 99px;
}
.resume__containerInfo > h3 {
  padding: 10px;
}
.resume__containerInfo > p {
  padding: 10px;
  /* font-style: italic;*/
  color: grey;
}
.progressbars {
  min-width: 280px;
  width: 50%;
  margin-top: 10px;
  height: 40px;
  border: 1px solid #444;
  background-color: lightgrey;
}
.progressbars > p {
  background-color: #444;
  z-index: 10;
  height: 38px;
}

@media (max-width: 550px) {
  .resume__containerTitle {
    margin: 0;
    margin-top: 10px;
    width: 100%;
    align-items: center;
  }
  .resume__containerTitle > h2 {
    margin: 0;
    text-align: center;
  }
  .resume__containerInfo {
    width: 80%;
    align-items: center;
  }
  .resume__containerTechs {
    justify-content: center;
  }
  .resume__containerTechs > img {
    margin: 5px;
    width: 60px;
    height: 60px;
  }
}
.resume__containerTechs > img:hover {
  animation: rotar 0.5s linear;
  cursor: pointer;
}
@keyframes rotar {
  0% {
    transform: rotate(0);
  }
  33% {
    transform: rotate(10deg);
  }
  66% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
