@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*font-family: "Roboto Mono", monospace, "opensans-bold", sans-serif;*/
  font-family: "Source Sans Pro", sans-serif;
}
html {
  scroll-behavior: smooth;
  /*background: url(./assets/sunset1.jpg);
  background-size: cover;*/
}

a {
  text-decoration: none;
  color: inherit;
}
.landingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100vh;
  position: absolute;
}
.landingPage > h1 {
  width: 100%;
  text-align: center;
  font-size: 25px;
  text-align: center;
  margin-top: 30px;
  color: white;
}

#card {
  width: 300px;
  height: 300px;
  background-image: url(/static/media/luna_opt.ee5641b3.jpg);
  background-size: 100vh;
  background-position: center;
  border-radius: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.fly {
  -webkit-animation: fly 1s infinite linear;
          animation: fly 1s infinite linear;
}
.scales {
  -webkit-animation: scales 10s linear infinite;
          animation: scales 10s linear infinite;
}
.dimensiones {
  -webkit-animation: dimensiones 3s linear forwards;
          animation: dimensiones 3s linear forwards;
}
@-webkit-keyframes fly {
  0% {
    transform: translateY(-35vh) scale(1);
  }
  50% {
    transform: translateY(-35vh) scale(0.9);
  }
  100% {
    transform: translateY(-35vh) scale(1);
  }
}
@keyframes fly {
  0% {
    transform: translateY(-35vh) scale(1);
  }
  50% {
    transform: translateY(-35vh) scale(0.9);
  }
  100% {
    transform: translateY(-35vh) scale(1);
  }
}
@-webkit-keyframes dimensiones {
  0% {
    transform: translateY(-30vh) scale(1) rotateY(0) translateZ(250px);
  }
  50% {
    transform: translateY(0) scale(1) rotateY(180deg) translateZ(500px);
  }
  100% {
    transform: translateY(0) scale(0.1) rotateY(360deg) translateZ(250px);
  }
}
@keyframes dimensiones {
  0% {
    transform: translateY(-30vh) scale(1) rotateY(0) translateZ(250px);
  }
  50% {
    transform: translateY(0) scale(1) rotateY(180deg) translateZ(500px);
  }
  100% {
    transform: translateY(0) scale(0.1) rotateY(360deg) translateZ(250px);
  }
}
@-webkit-keyframes scales {
  0% {
    transform: rotateZ(0);
  }
  50% {
    transform: rotateZ(180deg);
  }
  90% {
    transform: rotateZ(335deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
@keyframes scales {
  0% {
    transform: rotateZ(0);
  }
  50% {
    transform: rotateZ(180deg);
  }
  90% {
    transform: rotateZ(335deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@media (max-width: 1200px) {
  #card {
    height: 250px;
    width: 250px;
    background-size: 100vh;
  }
  .landingPage > h1 {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  #card {
    height: 225px;
    width: 225px;
  }
  .landingPage > h1 {
    font-size: 16px;
  }
  @-webkit-keyframes fly {
    0% {
      transform: translateY(-25vh) scale(0.8);
    }
    50% {
      transform: translateY(-25vh) scale(0.7);
    }
    100% {
      transform: translateY(-25vh) scale(0.8);
    }
  }
  @keyframes fly {
    0% {
      transform: translateY(-25vh) scale(0.8);
    }
    50% {
      transform: translateY(-25vh) scale(0.7);
    }
    100% {
      transform: translateY(-25vh) scale(0.8);
    }
  }
  @-webkit-keyframes dimensiones {
    0% {
      transform: translateY(-25vh) scale(0.8) rotateY(0) translateZ(250px);
    }
    50% {
      transform: translateY(0) scale(0.8) rotateY(180deg) translateZ(500px);
    }
    100% {
      transform: translateY(0) scale(0.1) rotateY(360deg) translateZ(250px);
    }
  }
  @keyframes dimensiones {
    0% {
      transform: translateY(-25vh) scale(0.8) rotateY(0) translateZ(250px);
    }
    50% {
      transform: translateY(0) scale(0.8) rotateY(180deg) translateZ(500px);
    }
    100% {
      transform: translateY(0) scale(0.1) rotateY(360deg) translateZ(250px);
    }
  }
}
@media (max-width: 500px) {
  html {
    background: url(/static/media/sunsetm.ba654e66.jpg);
    background-size: cover;
    background-attachment: fixed;
  }
}
@media (min-width: 2000px) {
  html {
    background: url(/static/media/sunset1.ee62f6aa.jpg);
    background-size: cover;
    background-attachment: fixed;
  }
  #card {
    background-image: url(/static/media/luna.c5420fdc.jpg);
    background-size: 100vh;
    background-position: center;
  }
}

.header {
  width: 100%;
  background: url(/static/media/sunset1.ee62f6aa.jpg);
  background-size: cover;
  background-position: bottom;

  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}
/*---------Navegacion-----------*/
.header__nav {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: bold;
  font-size: 12px;
  height: 50px;
}
.header__navLink {
  padding: 10px;
  margin-right: 10px;
  text-shadow: 1px 1px 1px 1px black;
  cursor: pointer;
  transition: 0.3s ease-in;
}
.header__navLink:hover {
  color: orange;
}
/*---------Info-------------*/
.header__info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header__info > h1 {
  width: 80%;
  text-align: center;
  font-size: 90px;
  padding: 10px;
  font-weight: 600;
  text-shadow: 1px 1px 1px black;
}
.header__info > p {
  width: 80%;
  text-align: center;
  color: white;
  font-size: 20px;
  text-shadow: 1px 1px 1px black;
}
.header__infoIcons {
  width: 80%;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.header__infoIcons a {
  padding: 20px;
  transition: 300ms all;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.068);
  box-shadow: 0 0 20px -10px white;
}
.header__infoIcons > a:hover {
  transform: scale(1.2);
}
/*----------Botón-----------*/
.header__more {
  margin-bottom: 40px;
  cursor: pointer;
  transition: 300ms all;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header__more:hover {
  transform: scale(1.2);
}

/*------MEDIA QUERIES---------*/
@media (max-width: 900px) {
  .header__info > h1 {
    font-size: 60px;
  }
  .header__info > p {
    font-size: 15px;
    padding: 20px;
  }
}
@media (max-width: 550px) {
  .header_nav {
    width: 100%;
  }

  .header__info > h1 {
    font-size: 50px;
    padding: 20px;
  }
  .header__info > p {
    font-size: 15px;
    padding: 20px;
  }
  .header__more {
    padding-bottom: 40px;
  }
}
@media (max-width: 500px) {
  #inicio {
    display: none;
  }
  #resumeOption {
    display: none;
  }
  .header__info > h1 {
    font-size: 35px;
  }
  .header__info > p {
    text-align: center;
    padding-right: 20px;
    padding-left: 20px;
  }
  .header__navLink {
    letter-spacing: 1.5px;
    padding-top: 10px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 11px;
  }
}

@media (max-width: 350px) {
  .header__navLink {
    margin: 10px 0;
    padding: 5px;
  }
}

.about {
  min-height: 50vh;
  background-color: #2b2b2b;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
.about__avatar {
  width: 40%;
  min-width: 150px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.about__avatar > img {
  border-radius: 50%;
  padding: 20px;
  object-fit: contain;
  margin-top: 80px;
  margin-right: 20px;
}
.about__section {
  width: 60%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 70px 40px 10px;
}
.about__section > h2 {
  padding: 10px;
  color: white;
}
.about__section > p {
  color: grey;
  padding: 10px;
  max-width: 650px;
}
@media (max-width: 500px) {
  .about__avatar {
    display: none;
  }
  .about__section {
    padding: 0;
    margin: 0;
    width: 100%;
    padding-top: 25px;
    padding-left: 20px;
    padding-bottom: 30px;
    padding-right: 10px;
  }

  .about__section > p {
    padding-right: 0;
    margin-right: 2px;
  }
}

.resume {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: white;
}
.resume__container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
.resume__containerTitle {
  padding: 10px;
  width: 40%;
  min-width: 200px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
}
.resume__containerTitle > h2 {
  margin-right: 50px;
  -webkit-text-decoration: underline orange;
          text-decoration: underline orange;
}
.resume__containerInfo {
  padding: 10px;
  width: 60%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.resume__containerTechs {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-left: 10px;
}
.resume__containerTechs > img {
  margin: 10px;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
  border-radius: 99px;
}
.circle {
  border-radius: 99px;
}
.resume__containerInfo > h3 {
  padding: 10px;
}
.resume__containerInfo > p {
  padding: 10px;
  /* font-style: italic;*/
  color: grey;
}
.progressbars {
  min-width: 280px;
  width: 50%;
  margin-top: 10px;
  height: 40px;
  border: 1px solid #444;
  background-color: lightgrey;
}
.progressbars > p {
  background-color: #444;
  z-index: 10;
  height: 38px;
}

@media (max-width: 550px) {
  .resume__containerTitle {
    margin: 0;
    margin-top: 10px;
    width: 100%;
    align-items: center;
  }
  .resume__containerTitle > h2 {
    margin: 0;
    text-align: center;
  }
  .resume__containerInfo {
    width: 80%;
    align-items: center;
  }
  .resume__containerTechs {
    justify-content: center;
  }
  .resume__containerTechs > img {
    margin: 5px;
    width: 60px;
    height: 60px;
  }
}
.resume__containerTechs > img:hover {
  -webkit-animation: rotar 0.5s linear;
          animation: rotar 0.5s linear;
  cursor: pointer;
}
@-webkit-keyframes rotar {
  0% {
    transform: rotate(0);
  }
  33% {
    transform: rotate(10deg);
  }
  66% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotar {
  0% {
    transform: rotate(0);
  }
  33% {
    transform: rotate(10deg);
  }
  66% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.projects {
  min-height: 40vh;
  background-color: lightgrey;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  padding-top: 25px;
}
.projects > h2 {
  margin-top: 20px;
  margin-bottom: 20px;
}
.projects__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.projects__contentItems {
  min-width: 300px;
  height: 200px;
  margin: 15px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  transition: 300ms ease-in;
  cursor: pointer;
  box-shadow: 0px 20px 30px 0 rgba(0, 0, 0, 0.25);
}
.projects__contentItems:hover {
  overflow: hidden;
  transform: scale(1.1);
}

.projects__contentItems > p {
  max-width: 300px;
  padding: 10px;
  padding-left: 15px;
  font-weight: 600;
  color: #444;
}
.projects__contentItems > img {
  width: 300px;
  height: 140px;
  object-fit: fill;
}

.footer {
  width: 100%;
  background-color: rgb(27, 27, 27);
  color: lightgrey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
}

.footer > a > img {
  margin-top: -80px;
  transition: 300ms all;
  cursor: pointer;
}
.footer > a > img:hover {
  transform: scale(1.2);
}

.footer__infoIcons {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer__infoIcons > p {
  color: grey;
  padding: 10px;
  margin-right: 20px;
}
.footer__infoIcons a {
  margin-right: 30px;
  cursor: pointer;
  transition: 300ms all;
}
.footer__infoIcons > a:hover {
  transform: scale(1.2);
}
.footer > p {
  padding: 10px;
  margin: 20px 0;
}
@media (max-width: 500px) {
  .footer__infoIcons > p {
    margin-right: 5px;
  }
  .footer__infoIcons > a {
    margin-right: 15px;
  }
}

