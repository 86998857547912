/*@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");*/
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*font-family: "Roboto Mono", monospace, "opensans-bold", sans-serif;*/
  font-family: "Source Sans Pro", sans-serif;
}
html {
  scroll-behavior: smooth;
  /*background: url(./assets/sunset1.jpg);
  background-size: cover;*/
}

a {
  text-decoration: none;
  color: inherit;
}
.landingPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100vh;
  position: absolute;
}
.landingPage > h1 {
  width: 100%;
  text-align: center;
  font-size: 25px;
  text-align: center;
  margin-top: 30px;
  color: white;
}

#card {
  width: 300px;
  height: 300px;
  background-image: url(./assets/luna_opt.jpg);
  background-size: 100vh;
  background-position: center;
  border-radius: 50%;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.fly {
  animation: fly 1s infinite linear;
}
.scales {
  animation: scales 10s linear infinite;
}
.dimensiones {
  animation: dimensiones 3s linear forwards;
}
@keyframes fly {
  0% {
    transform: translateY(-35vh) scale(1);
  }
  50% {
    transform: translateY(-35vh) scale(0.9);
  }
  100% {
    transform: translateY(-35vh) scale(1);
  }
}
@keyframes dimensiones {
  0% {
    transform: translateY(-30vh) scale(1) rotateY(0) translateZ(250px);
  }
  50% {
    transform: translateY(0) scale(1) rotateY(180deg) translateZ(500px);
  }
  100% {
    transform: translateY(0) scale(0.1) rotateY(360deg) translateZ(250px);
  }
}
@keyframes scales {
  0% {
    transform: rotateZ(0);
  }
  50% {
    transform: rotateZ(180deg);
  }
  90% {
    transform: rotateZ(335deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@media (max-width: 1200px) {
  #card {
    height: 250px;
    width: 250px;
    background-size: 100vh;
  }
  .landingPage > h1 {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  #card {
    height: 225px;
    width: 225px;
  }
  .landingPage > h1 {
    font-size: 16px;
  }
  @keyframes fly {
    0% {
      transform: translateY(-25vh) scale(0.8);
    }
    50% {
      transform: translateY(-25vh) scale(0.7);
    }
    100% {
      transform: translateY(-25vh) scale(0.8);
    }
  }
  @keyframes dimensiones {
    0% {
      transform: translateY(-25vh) scale(0.8) rotateY(0) translateZ(250px);
    }
    50% {
      transform: translateY(0) scale(0.8) rotateY(180deg) translateZ(500px);
    }
    100% {
      transform: translateY(0) scale(0.1) rotateY(360deg) translateZ(250px);
    }
  }
}
@media (max-width: 500px) {
  html {
    background: url(./assets/sunsetm.jpg);
    background-size: cover;
    background-attachment: fixed;
  }
}
@media (min-width: 2000px) {
  html {
    background: url(./assets/sunset1.jpg);
    background-size: cover;
    background-attachment: fixed;
  }
  #card {
    background-image: url(./assets/luna.jpg);
    background-size: 100vh;
    background-position: center;
  }
}
